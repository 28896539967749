import React from 'react';
import logo from './logo.svg';
import apSt from './apst.svg';
import './style/App.css';
import info from './info.js';

function App() {
  return (
    <div className="App">
      <img src="https://lh3.googleusercontent.com/HYFsfecFHhjBgPPDEumFDa1D8jTOan6VIgGATLQgg645HXqsPOp2kiBLYMIpmuHY-ikvvA=s118"/>
      <h>Hi this site in under way by Schroader Enterprise </h>
    </div>
  );
}

export default App;
